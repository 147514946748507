import * as React from 'react'
import PageHeader from '../components/PageHeader'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import Notes from '../components/Notes'
import Footer from '../components/Footer'
import ImageExport from '../components/ImageExport'
import SP51Chart from '../components/SP51/SP51Chart'
//import InsufficientData from '../components/InsufficientData'
import SP51Table from '../components/SP51/SP51Table'
import {
  WHO_LOCATION_OPTIONS_ALT,
  INC_LOCATION_OPTIONS_ALT,
  UNI_LOCATION_OPTIONS_ALT
} from '../utils/const'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'

const Sp51Page = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { sectionId } = pageContext
  const { content, footerContent } = data
  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')

  //const isGbl = reg === 'GBL' || !reg
  const regionLabel = t('global')
  //**CURRENTLY ONLY GLOBAL DATA FOR SP5.1  */

  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS_ALT}
          incOptions={INC_LOCATION_OPTIONS_ALT}
          uniOptions={UNI_LOCATION_OPTIONS_ALT}
          sectionId={sectionId}
        />
        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>

        <>
          <ImageExport
            title={`${content.chartTitle}—${regionLabel}`}
            dataSource={content.dataSourceNotesRt}
          >
            <SP51Chart data={data.global.nodes} />
          </ImageExport>
          <SP51Chart data={data.global.nodes}></SP51Chart>
          <SP51Table regions={data.global.nodes}></SP51Table>
        </>
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Sp51Page

// iso3 is null for global page but this works because it is the only null one
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleSp51CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    global: allRegions2(
      filter: { IA2030_IND: { eq: "SP5.1" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        YEAR_RANGE
        INDCODE
        DIMENSION2
        NUMERATOR
        DENOMINATOR
        VALUE_NUM
      }
    }
  }
`
// global: googleSp51Sheet(regionType: { eq: "GBL" }) {
//   id
//   iSO3
//   regionType
//   memberState
//   totalTimelyBaseline
//   totalTimely2021
//   totalTimely2022
//   totalUnTimelyBaseline
//   totalUnTimely2021
//   totalUnTimely2022
//   ebolaTimelyBaseline
//   ebolaTimely2021
//   ebolaTimely2022
//   ebolaUnTimelyBaseline
//   ebolaUnTimely2021
//   ebolaUnTimely2022
//   yellowFeverTimelyBaseline
//   yellowFeverTimely2021
//   yellowFeverTimely2022
//   yellowFeverUnTimelyBaseline
//   yellowFeverUnTimely2021
//   yellowFeverUnTimely2022
//   meningTimelyBaseline
//   meningTimely2021
//   meningTimely2022
//   meningUnTimelyBaseline
//   meningUnTimely2021
//   meningUnTimely2022
//   choleraTimelyBaseline
//   choleraTimely2021
//   choleraTimely2022
//   choleraUnTimelyBaseline
//   choleraUnTimely2021
//   choleraUnTimely2022
//   measlesTimelyBaseline
//   measlesTimely2021
//   measlesTimely2022
//   measlesUnTimelyBaseline
//   measlesUnTimely2021
//   measlesUnTimely2022
//   cVDPVTimelyBaseline
//   cVDPVTimely2021
//   cVDPVTimely2022
//   cVDPVUnTimelyBaseline
//   cVDPVUnTimely2021
//   cVDPVUnTimely2022
//   wPVTimelyBaseline
//   wPVTimely2021
//   wPVTimely2022
//   wPVUnTimelyBaseline
//   wPVUnTimely2021
//   wPVUnTimely2022
// }
