import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const LegendBox = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  background-color: ${({ color }) => `var(--${color})`};
  margin-right: 0.75rem;
`
const LegendLabel = styled.div`
  font-size: 1.2rem;
`
const LegendBlank = styled.div`
  width: 2.2rem;
  min-width: 2.2rem;
  height: 2.2rem;
  outline: 1px solid var(--lightGray);
  outline-offset: -1px;
  background: ${({ print }) =>
    print
      ? 'none'
      : `linear-gradient(
	to top right,
	rgba(0, 0, 0, 0) 0%,
	rgba(0, 0, 0, 0) calc(50% - 0.8px),
	rgba(0, 0, 0, 0.25) 50%,
	rgba(0, 0, 0, 0) calc(50% + 0.8px),
	rgba(0, 0, 0, 0) 100%
)`};
  background-color: ${({ print }) =>
    print ? 'var(--lightestGray)' : 'var(--white)'};
  border: 1px solid var(--lightGray);
  margin-right: 0.75rem;
`
const LegendGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0 3rem 1rem 0;
`

const LegendRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 4rem 0 3rem;
`
const Legend = ({ sp, print }) => {
  const { t } = useTranslation()
  return (
    <LegendRow>
      <LegendGroup>
        <LegendBox color={'brightBlue'} />
        <LegendLabel>
          {sp === '5.1' ? ` = ${t('timely')}` : ` = ${t('yes')}`}
        </LegendLabel>
      </LegendGroup>
      <LegendGroup>
        <LegendBox color={'paleBlue'} />
        <LegendLabel>
          {sp === '5.1' ? ` = ${t('untimely')}` : ` = ${t('no')}`}
        </LegendLabel>
      </LegendGroup>
      <LegendGroup>
        {sp !== '5.1' && (
          <>
            <LegendBlank print={print} />
            <LegendLabel>
              {` = ${t('No data/Not relevant/No response')}`}
            </LegendLabel>
          </>
        )}
      </LegendGroup>
    </LegendRow>
  )
}

export default Legend
